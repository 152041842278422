import React, { Fragment } from "react"
//import styled from "styled-components"
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Nav from '../components/Nav'

const NotFoundPage = () => {
  return (
    <Fragment>
      <CssBaseline />
      <Nav />
      <Container>
        <Typography variant="h1" component="h2" gutterBottom>
          Sivua ei löytynyt!
        </Typography>
      </Container>
    </Fragment>
  )
}

export default NotFoundPage
